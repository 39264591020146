import React from "react";

function App() {
  return (
    <div>
      <h1>Porfolio project - Pedro Santos</h1>
    </div>
  );
}

export default App;
